<!-- 案例详情 -->
<template>
    <div class="caseDetails">
        <div class="banner">
            <h2>已为<span>58家</span>企业提供服务</h2>
            <p>
                致力于为城镇、园区、景区、公建项目、综合体、高校、等各类不动产持有方、物业管理企业提供系统化整体性解决方案
            </p>
        </div>
        <!-- 面包屑 -->
        <div class="bread-crumb">
            <div class="crumb">
                <span>平正云科技</span>
                <img src="../../assets/customerCase/right@3x.png" alt="" />
            </div>
            <div class="crumb" @click="customerCase()">
                <span>案例中心</span>
                <img src="../../assets/customerCase/right@3x.png" alt="" />
            </div>
            <div class="crumb">
                <span>{{ dataList[which].title }}</span>
                <!-- <img src="../../assets/customerCase/right@3x.png" alt="" /> -->
            </div>
        </div>
        <!-- 详情内容 -->
        <div class="content">
            <div class="article-container">
                <div class="title">
                    <h2>{{ dataList[which].title }}</h2>
                    <span>{{ dataList[which].time }}</span>
                </div>
                <div class="details">
                    <h4>项目背景</h4>
                    <img :src="dataList[which].wallPic" alt="" />
                    <div class="info">
                        <div class="details-numbers" v-for="(item2, index2) in dataList[which].infoList" :key="index2">
                            <h5>{{ item2.numbers }}</h5>
                            <h5>{{ item2.name }}</h5>
                        </div>
                    </div>
                    <article class="article">
                        {{ dataList[which].article }}
                    </article>
                    <div class="first-paragraph">
                        <h4>{{ dataList[which].firsth4 }}</h4>
                        <div class="container">
                            <template>
                                <div class="item" v-for="(item4, index4) in dataList[which].firstDetails" :key="index4">
                                    <span>{{ item4.title }}</span>
                                    <p v-for="(item5, index5) in item4.details" :key="index5">
                                        {{ item5 }}
                                    </p>
                                </div>
                            </template>

                            <!-- <p>
                                痛点二：成本核算代替成本管控。几乎所有公立医都没有成本管理机构。不少院长把成本管理的责任落在财务科，甚至依赖一套成本核算来解决成本问题，结果是成本越来越高。
                            </p>
                            <p>
                                痛点三：不切实际的管理模式照搬，医院的分配方法有许多种，没有一种方法可以适应所有医院。而医院的分配是管理中最难的。
                            </p> -->
                        </div>
                    </div>
                    <div class="second-paragraph">
                        <h4>{{ dataList[which].secondh4 }}</h4>
                        <p v-for="(item3, index3) in dataList[which].secondDetails" :key="index3">
                            {{ item3 }}
                        </p>
                    </div>
                </div>
            </div>
            <div class="extra">
                <div class="card">
                    <div class="title">相关产品</div>
                    <div class="details" v-for="(item4, index4) in productList" :key="index4">
						<router-link :to="item4.path">
							<img src="../../assets/customerCase/icon@3x.png" alt="" />
							<div class="container">
								<h4>{{item4.name}}</h4>
								<span>查看详情>></span>
							</div>
						</router-link>
                    </div>
                </div>
                <!-- 相关案例 -->
                <!-- <div class="case-list">
                    <div class="title">相关产品</div>
                    <ul>
                        <li v-for="(item, index) in caseList" :key="index" @click="proClick(index)">{{ item.name }}</li>
                    </ul>
                </div> -->
            </div>
        </div>
    </div>
</template>

<script>
export default {
    components: {},
    data() {
        return {
			productList: [
				{ 
					name: "智慧作业平台", 
					path: "/product" ,
				},
				{
					name: "协同治理平台", 
					path: "/product2" ,
				},
				{
					name: "数字化内推平台", 
					path: "/product3" ,
				},
			],
            breadList: [
                { name: "平正云科技", path: "", img: require("../../assets/customerCase/right@3x.png") },
                { name: "案例中心", path: "", img: require("../../assets/customerCase/right@3x.png") },
                { name: "平正云科技", path: "" },
            ], //面包屑
            infoList: [
                { name: "管理人数", numbers: "2589" },
                { name: "合同数据(份)", numbers: "398" },
                { name: "考勤数据(条)", numbers: "2W+" },
            ],
            caseList: [
                { name: "上海财经大学千村调研2.0系统" },
                { name: "保利物业百优行动品检项目" },
                { name: "西塘全域化公共服务项目" },
                // { name: "西塘古镇景区公共服务管理 " },
                // { name: "嘉兴西塘古镇景区物业服务中心" },
            ], //相关案例
            dataList: [
                {
                    title: "上海财经大学千村调研2.0系统",
                    time: "2020-06-12 00:00:00",
                    wallPic: require("../../assets/customerCase/1.jpg"),
                    infoList: [
                        { name: "调研队伍", numbers: "1500+" },
                        { name: "调研村庄", numbers: "2000+" },
                        { name: "调研学生", numbers: "5000+" },
                    ],
                    article:
                        "“千村调查”项目是上海财经大学“211工程”三期创新人才培养项目，已成功实施十三期。“走千村，访万户，读中国”。“千村调查”项目是以 “三农”问题为研究对象的大型社会实践和社会调查研究项目，旨在通过专业的社会调查获得我国“三农”问题的数据资料，形成调查研究报告和决策咨询报告，供国家相关部门决策参考；同时“千村调查”也是国家教育体制改革试点项目之一“财经创新人才培养模式”项目的重要内容，是集社会实践、专业学习、科学研究、创新能力培养为一体的人才培养模式探索。",
                    firsth4: "客户需求",
                    firstDetails: [
                        { title: "01 调研过程", details: ["规范标准 动态管控 管理有痕 实时展示"] },
                        { title: "02 调查数据", details: ["实时采集 及时存储 规范整理 初步分析"] },
                        { title: "03 调研学生", details: ["操作学习 问题咨询 数据输入 资料存储"] },
                        { title: "04 调研老师", details: ["任务分工 操作指导 进度管控 成果监督"] },
                    ],
                    secondh4: "成果",
                    secondDetails: [
                        `“白天调研，晚上数据录入” 规律初步形成，“边调研，边数据录入” 趋势形成；
                        在定点调研区域内尽量完成数据录入的目标基本实现，定点调查组管理机制及管理能力效应显著；
                        在过程管控体系有效运行的前提下，实现“调查痕迹可循、调查行为可控、调查结果可追溯”的返乡调查管理目标。`,
                    ],
                },
                {
                    title: "保利物业百优行动品检项目",
                    time: "2020-06-12 00:00:00",
                    wallPic: require("../../assets/customerCase/2.jpg"),
                    infoList: [
                        { name: "分公司(家)", numbers: "26" },
                        { name: "项目(个)", numbers: "373" },
                        { name: "检查天数(天)", numbers: "100" },
                    ],
                    article:
                        "“百优行动”是保利物业提升服务品质的专项行动，以物业管理基础服务触点动作为契机，采用场景式宣传，标准化服务为切入点，来加强客户感知、强调执行标准，进而提升客户满意度。该“行动”的主要目标是打造“三个100”，即100天品质提升，100%项目参与，100个项目评优。",
                    firsth4: "客户需求",
                    firstDetails: [
                        {
                            title: "01 业务管控平台化",
                            details: ["管理行为、管理过程、管理结果的集合层", "法律法规、管理制度、规定文件的执行层"],
                        },
                        {
                            title: "02 管理规则标准化",
                            details: ["规范管理动作，保留管理痕迹，降低管理风险，充分贯彻集团管理要求。"],
                        },
                        {
                            title: "03 过程管理精细化",
                            details: [
                                "运用PDCA法的整改功能，实现闭合管理，确保提升服务品质。",
                                "品质检查并不是目的，是提升服务品质的手段。",
                            ],
                        },
                    ],
                    secondh4: "成果",
                    secondDetails: [
                        `统一检查标准，形成350项检查标准，覆盖内部调控、绿化保洁、工程与安全、安防客服等方面
                        检查数据在系统内进行对比、分析，进一步验证成绩的真实性、可靠性，让检查过程更加严谨。
                        系统运行平稳，检查结果及时显示，检查进度有效控制，有效促进检查工作推进，确保“百优行动”的顺利实施。`,
                    ],
                },
                {
                    title: "西塘全域化公共服务项目",
                    time: "2020-06-12 00:00:00",
                    wallPic: require("../../assets/customerCase/3.jpg"),
                    infoList: [
                        { name: "分公司(家)", numbers: "453" },
                        { name: "项目(个)", numbers: "10" },
                        { name: "检查天数(天)", numbers: "2w+" },
                    ],
                    article:
                        "2020年9月26日，保利物业正式进驻西塘全域。本次服务升级，旨在将“政府主导、企业协同、服务对象积极参与”的三位一体全域化服务，打造出长三角示范区的社会治理新模式，为加快形成“共建共治共享”社会治理格局贡献力量。",
                    firsth4: "客户需求",
                    firstDetails: [
                        {
                            title: "01 人车物事全链管理",
                            details: ["需要数字底座作为支持", "需要数字化展示窗口"],
                        },
                        {
                            title: "02 丰富业务场景提升盈利水平",
                            details: ["深入探索增值服务", "拓展项目场景边际"],
                        },
                        {
                            title: "03 数字驱动组织升级",
                            details: ["作业标准学习成本高", "评价机制尚待完善"],
                        },
                        {
                            title: "04 建立有效政企协同机制",
                            details: ["“三位一体”全域化服务模式", "助力政府职能的转化和高质量发展"],
                        },
                    ],
                    secondh4: "成果",
                    secondDetails: [
                        `进驻西塘古镇后，保利物业通过整合城镇资源，构建基于网络数据体系的“治理——服务——监督”一体化信息环境，形成 共建共享、协同赋能的枢纽平台，实现了服务能力的加速提升。`,
                    ],
                },
            ],
            which: 0,
            whichClone: Number,
        };
    },
    computed: {},
    watch: {},
    created() {
        this.which = this.$route.params.index || this.which;
    },
    mounted() {},
    methods: {
        // 跳转到案例中心
        customerCase() {
            this.$router.push({ path: "/customerCase" });
        },
        // 相关产品
        proClick(index) {
            this.which = index;
            scrollTo(0, 350);
        },
    },
};
</script>

<style scoped lang="scss">
@media screen and (min-width: 751px) {
    @import "caseDetails_PC.scss";
}
@media screen and (max-width: 750px) {
    @import "caseDetails_mobile.scss";
}
</style>
